import { Instagram, Twitter, Linkedin } from "lucide-react";

const HomePage = () => {
  return (
    <>
      <div className="hero-section min-h-[740px]">
        <div className="wrapper h-[700px] flex flex-col items-center pt-[200px]">
          <h1 className="text-center text-6xl font-bold mb-2">
            AI Solutions for Modern World
          </h1>
          <p className="text-2xl text-center max-w-[600px]">
            We believe the future of AI lies in its ability to automate and
            enhance human decision-making.
          </p>

          <div className="flex flex-col items-center my-6">
            <img
              className="h-[80px]"
              src="./inception.png"
              alt="NVIDIA Inception Program"
            />
          </div>
        </div>
      </div>

      <div className="wrapper mb-20 space-y-4">
        <h2 className="text-3xl font-semibold mb-4">Who We Are</h2>
        <p>
          Algrow Digital is a company at the intersection of cutting-edge
          technology and strategic innovation.
        </p>
        <p>
          Currently, we are deep into research and development, focusing on
          solutions that have the potential to transform industries and empower
          businesses to operate more efficiently. Our approach is holistic,
          considering not just the technical aspects of AI, but also the ethical
          and practical implications of its deployment.
        </p>
      </div>
      
      <div className="wrapper mb-20 space-y-4">
        <h2 className="text-3xl font-semibold mb-4">Our Product</h2>
        <h3 className="text-2xl mb-2">Interview Plus</h3>
        <p>
          Designed to streamline and enhance the initial interview process for
          large corporations. Its primary use case is to conduct initial round
          interviews through advanced AI-driven interactions. The product is
          tailored to:
        </p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>Automate Screening:</strong> Efficiently handle high volumes
            of candidate applications by conducting initial interviews, saving
            time for HR teams.
          </li>
          <li>
            <strong>Ensure Consistency:</strong> Provide a standardized
            interview experience that reduces bias and ensures fair evaluation
            across all candidates.
          </li>
          <li>
            <strong>Enhance Candidate Experience:</strong> Offer a realistic and
            engaging interview that mimics human interaction, making the process
            feel more personal and less mechanical.
          </li>
          <li>
            <strong>Gather Insights:</strong> Use sophisticated natural language
            processing to assess candidates’ responses, providing valuable
            insights and recommendations for the next stages of the hiring
            process.
          </li>
        </ul>
        <p>
          By automating these early stages, "Interview Plus" helps organizations
          focus their resources on the most promising candidates, ultimately
          improving the efficiency and effectiveness of their hiring strategy.
        </p>
      </div>

      <div className="wrapper mb-20 space-y-4">
        <h2 className="text-3xl font-semibold mb-4">Our Vision</h2>
        <h3 className="text-2xl mb-2">Looking to the Future</h3>
        <p>
          Algrow Digital’s vision extends far beyond our current offerings. We
          are on a mission to become a leader in AI-driven innovation across
          industries. Our goal is to build a comprehensive suite of AI tools
          that businesses can rely on to achieve unprecedented levels of
          efficiency, creativity, and growth.
        </p>
        <p className="my-3">Our Future Focus:</p>
        <ul className="list-disc pl-5 space-y-2">
          <li>
            <strong>AI Integration:</strong> Seamless incorporation of AI into
            daily business operations.
          </li>
          <li>
            <strong>Human-Centric Design:</strong> Enhancing, not replacing,
            human capabilities with AI.
          </li>
          <li>
            <strong>Ethical AI:</strong> Continuous commitment to fairness,
            transparency, and responsible AI development.
          </li>
        </ul>
        <p>
          As we continue to grow, our dedication to ethical AI development will
          remain steadfast, ensuring that our solutions not only deliver value
          but also uphold the highest standards of integrity.
        </p>
      </div>

      <div className="wrapper mb-20 space-y-4">
        <h2 className="text-3xl font-semibold mb-4">Contact Us</h2>
        <p>
          At Algrow Digital, we value collaboration and are always eager to
          connect with visionary partners, investors, and collaborators who
          share our passion for innovation. If you are interested in learning
          more about our work, discussing potential opportunities, or simply
          want to stay informed about our progress, we would love to hear from
          you.{" "}
        </p>
        <div className="flex items-center gap-4 bg-dark">
          <a href="https://www.instagram.com/algrow.digital/">
            <Instagram>Instagrom</Instagram>
          </a>
          <a href="https://twitter.com/algrow_digital">
            <Twitter>Twitter</Twitter>
          </a>
          <a href="https://www.linkedin.com/company/algrow-digital/">
            <Linkedin>Linkedin</Linkedin>
          </a>
          <a
            href="mailto:contact@algrowdigital.com"
            className="font-extrabold ml-auto"
          >
            contact@algrowdigital.com
          </a>
        </div>
      </div>
    </>
  );
};

export default HomePage;
