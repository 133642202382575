import React from "react";

/**
 * 404 ERROR Page
 */
const KnowWhere = () => {
  return (
    <div>
      <h1>404 | Not Found</h1>
    </div>
  );
};

/**
 * Loader for Lazyloaded components.
 */
const LoadingOverlay = () => {
  return <h1>Loading</h1>;
};

export { LoadingOverlay };
export default KnowWhere;
