// Modules
import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";

const AppBody = () => {
  useEffect(() => {
    document.querySelector('html').classList.add('dark');
  }, []);
  return (
    <main className="text-gray-600 dark:text-gray-400">
      <Outlet />
    </main>
  );
};

export default AppBody;
