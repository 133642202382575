import { Link } from "react-router-dom";
import { Sun, Moon } from "lucide-react";
import { useEffect, useState } from "react";

const Header = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);

  useEffect(() => {
    const isDark = document.querySelector("html").classList.contains("dark");
    setIsDarkMode(isDark);
  }, []);

  function toggleTheme() {
    document.querySelector("html").classList.toggle("dark");
    setIsDarkMode(!isDarkMode);
  }

  return (
    <div className="h-[56px]">
      <div className="wrapper h-full py-1 flex justify-between items-center">
        <Link href={"/"} className="font-semibold text-2xl">Algrow Digital</Link>

        <div className="flex items-center">
          <button onClick={toggleTheme} className="focus:outline-none cursor-pointer">
            {isDarkMode ? <Sun /> : <Moon />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Header;
