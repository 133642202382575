import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  value: 0,
};

export const counterSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    someReducer() {
      return null;
    },
  },
});

// Action creators are generated for each case reducer function
export const { someReducer } = counterSlice.actions;

export default counterSlice.reducer;
