import React, { lazy, Suspense } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomePage from "./pages/HomePage";
// import Footer from "./components/Footer";
import Header from "./components/Header";
import AppBody from "./AppBody";
import { LoadingOverlay } from "./components/KnowWhere";

// Lazy Loading Components.
const KnowWhere = lazy(() => import("./components/KnowWhere"));

const App = () => {
  return (
    <div className="app">
      <BrowserRouter>
        <Header />
        <Suspense fallback={<LoadingOverlay />}>
          <Routes>
            <Route path="/" element={<AppBody />}>
              <Route index element={<HomePage />} />
              <Route path="*" element={<LoadingOverlay />} />
            </Route>
          </Routes>
        </Suspense>
        {/* <Footer /> */}
      </BrowserRouter>
    </div>
  );
};

export default App;
